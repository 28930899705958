<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-no-padding">
            <ion-item lines="full" mode="md" @click="route(group)" v-for="group of imageGroupTypes.keys()" :key="group">
                <ion-label>
                    <h2>{{ imageGroupTypes.get(group) }}</h2>
                </ion-label>
                <DirectionIndicator class="edit-forward" :is-ltr-forward="true" />
            </ion-item>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import { IonContent, IonItem, IonPage } from "@ionic/vue";
import { store } from '@/store/Store';
import router from "@/router";
import { IMAGE_GROUP_TYPES} from "@/models/ImageGroupTypes";
import {useStore} from "vuex";
import {computed} from "vue";
import {defineComponent} from "@vue/runtime-core";
import DirectionIndicator from "@/components/DirectionIndicator.vue";

export default defineComponent({
    name: "ImageGroups",
    components: {
        DirectionIndicator,
        IonContent,
        IonPage,
        IonItem
    },
    async ionViewWillEnter() {
        await store.dispatch('app/setPageTitle', this.language.imageGroups);
    },
    setup() {
        const imageGroupTypes = IMAGE_GROUP_TYPES;
        const route = (id: string) => {
            router.push({ name: 'imageGroupDetails', params: { id: id } });
        }
        
        return {
            store: useStore(),
            route,
            imageGroupTypes,
            language: computed(() => store.getters['app/language'])
        }
    }
});
</script>

<style scoped>

</style>