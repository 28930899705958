
import { IonContent, IonItem, IonPage } from "@ionic/vue";
import { store } from '@/store/Store';
import router from "@/router";
import { IMAGE_GROUP_TYPES} from "@/models/ImageGroupTypes";
import {useStore} from "vuex";
import {computed} from "vue";
import {defineComponent} from "@vue/runtime-core";
import DirectionIndicator from "@/components/DirectionIndicator.vue";

export default defineComponent({
    name: "ImageGroups",
    components: {
        DirectionIndicator,
        IonContent,
        IonPage,
        IonItem
    },
    async ionViewWillEnter() {
        await store.dispatch('app/setPageTitle', this.language.imageGroups);
    },
    setup() {
        const imageGroupTypes = IMAGE_GROUP_TYPES;
        const route = (id: string) => {
            router.push({ name: 'imageGroupDetails', params: { id: id } });
        }
        
        return {
            store: useStore(),
            route,
            imageGroupTypes,
            language: computed(() => store.getters['app/language'])
        }
    }
});
